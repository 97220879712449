import { ref } from 'vue'
import bindCard from '../Content/images/faqs/bindCard.png'
import grab from '../Content/images/faqs/grab.png'
import pwd from '../Content/images/faqs/pwd.png'
import recharge from '../Content/images/faqs/recharge.png'
import withdraw from '../Content/images/faqs/withdraw.png'

import bindCard_pb from '../Content/images/faqs/bindCard_pb.jpg'
import grab_pb from '../Content/images/faqs/grab_pb.jpg'
import pwd_pb from '../Content/images/faqs/pwd_pb.jpg'
import recharge_pb from '../Content/images/faqs/recharge_pb.jpg'
import withdraw_pb from '../Content/images/faqs/withdraw_pb.jpg'
export const faqs=ref(
    [
        {
            id:0,
            question:'common.faqs.bindCard',
            answer:bindCard,
            answer_pb:bindCard_pb
        },
        {
            id:1,
            question:'common.faqs.grab',
            answer:grab,
            answer_pb:grab_pb
        },
        {
            id:2,
            question:'common.faqs.pwd',
            answer:pwd,
            answer_pb:pwd_pb
        },
        {
            id:3,
            question:'common.faqs.recharge',
            answer:recharge,
            answer_pb:recharge_pb
        },
        {
            id:4,
            question:'common.faqs.withdraw',
            answer:withdraw,
            answer_pb:withdraw_pb
        },
    ]
)