<template>
  <div class="service wrap_service">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to="/profile">
            <i class="iconfont icon-fanhui"></i>
          </router-link>
        </li>
        <li>{{ t('common.title.service') }}</li>
      </ul>
    </div>
    <div class="main_page">
      <!-- Main body started -->
      <div class="one">
        <!-- <h1>{{ t('common.title.service') }}</h1> -->
        <div class="text text-pre-line">{{ t('common.text.C_service') }}</div>
        <ul>
          <li>
            <!-- <span>{{ t('service.label.advisory') }}</span> -->
            <p>
              <a :href="`https://api.whatsapp.com/send/?phone=${sysDataDictionaryWbObj?.WHATSAPP?.dataContent}`">
                <!-- <StaticImage url="site_08.png" alt="" /> -->
                <i class="iconfont icon-kefu"></i>
                <b>{{ t('service.label.advisory') }}</b>
              </a>
            </p>
          </li>
          <li>
            <!-- <span>{{ t('service.label.complaint') }}</span> -->
            <p>
              <a :href="`https://api.whatsapp.com/send/?phone=${sysDataDictionaryWbObj?.WHATSAPP?.dataContent}`">
                <!-- <StaticImage url="site_09.png" alt="" /> -->
                <i class="iconfont icon-kefu"></i>
                <b>{{ t('service.label.complaint') }}</b>
              </a>
            </p>
          </li>
        </ul>
      </div>
      <!-- <div class="two">
        <div class="title">{{ t('service.label.faq') }}</div>
        <div v-if="faqs && faqs.length > 0" class="two_box">
          <a-collapse :bordered="false" accordion>
            <template #expandIcon="{ isActive }">
              <caret-right-outlined :rotate="isActive ? 90 : 0" />
            </template>
            <a-collapse-panel
              v-for="faq in faqs"
              :key="faq.id"
              :header="t(faq.question)"
            >
              <img class="answerImg" :src="faq.answer" v-if="locale==='en'"/>
              <img class="answerImg" :src="faq.answer_pb" v-if="locale==='pb'"/>
            </a-collapse-panel>
          </a-collapse>
        </div>
      </div> -->
      
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
// import { CaretRightOutlined } from '@ant-design/icons-vue';
// import StaticImage from '@/components/StaticImage.vue';
import { sysDataDictionaryWbObj } from '@/hooks/sysDataDictionaryWbObj'
import { useI18n } from '@/lang';
import {faqs} from '@/hooks/faqs'
export default defineComponent({
  // components: { CaretRightOutlined, 
  // // StaticImage 
  // },
  setup() {
    const { t,locale } = useI18n();
    console.log(faqs.value)
    return {
      t,
      locale,
      faqs,
      sysDataDictionaryWbObj,
    };
  },
});
</script>

<style lang="scss" scoped>
.text-pre-line {
  white-space: pre-line;
}
.answerImg{
  width: 100%;
}
</style>
